@charset "utf-8";

@import "so-simple";

#publications-main {
    border-top: 1px solid;
    border-top-color: #cccccc;
    margin-top: 2em;
    padding: 1.5em 0;
    font-size: 0.889rem;

    display: flex;
    flex-direction: column;

    h2 {
        text-align: center;
        margin-bottom: 0em;
    }

    .publication {
        display: flex;
        flex-direction: column;
        margin: 0 2rem 1.5em;
    }

    .publication-year {
        text-align: center;
        margin: 0.75em 0;
    }

    .title {
        font-size: 1.067em;
    }

}